import { CSSProperties } from "react";

export const BORDER_RADIUS = '6px';

export const FORM_ITEM_RADIUS = '5px';

export const CARD_BOX_SHADOW = '0 2px 4px rgb(126 142 177 / 12%)';

// color
export const THEME = '#153D77';
export const EXTRA = '#2469CE';
export const COLUMN_CELL_BG = '#F8F9FA';
export const LINK_SKY_BLUE = '#1890FF';

// font
export const JOST = 'Jost,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif';
export const HIND = '"Hind Vadodara",-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif';

// 间距
export const GAP_SIZE = '28px';

// 圆角和字体
export const formItemCommonCSS: CSSProperties = {
  fontFamily: HIND,
  borderRadius: FORM_ITEM_RADIUS
};

// chart height
export const SINGLE_T_TEST_CHART_HEIGHT = '440px';
export const SINGLE_T_TEST_CHART_WRAPPER_HEIGHT = '500px';
export const SINGLE_CNV_SCATTER_HEIGHT = '400px';

export const SINGLE_T_TEST_CHART_MD_HEIGHT = '660px';
export const SINGLE_T_TEST_CHART_MD_WRAPPER_HEIGHT = '720px';
export const SINGLE_CNV_SCATTER_MD_HEIGHT = '600px';
