import {
  HomeOutlined,
  AppstoreOutlined,
  ExperimentOutlined,
  FolderOpenOutlined,
  CloudUploadOutlined,
  QuestionCircleOutlined,
  WhatsAppOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { ReactNode } from 'react';
import Loadable, { LoadableComponent } from '@loadable/component';

const Home = Loadable(() => import(/* webpackChunkName:"home" */ '../pages/Home'));
const MRNALncRNA = Loadable(() => import(/* webpackChunkName:"MRNALncRNA" */ '../pages/MRNALncRNA'));
const MiRNA = Loadable(() => import(/* webpackChunkName:"MiRNA" */ '../pages/MiRNA'));
const Methylation = Loadable(() => import(/* webpackChunkName:"Methylation" */ '../pages/Methylation'));
const CNV = Loadable(() => import(/* webpackChunkName:"CNV" */ '../pages/CNV'));
const DifferentialAnalysis = Loadable(
  () => import(/* webpackChunkName:"DifferentialAnalysis" */ '../pages/DifferentialAnalysis')
);
const TMEResults = Loadable(() => import(/* webpackChunkName:"TMEResults" */ '../pages/TMEResults'));
const SurvivalAnalysis = Loadable(() => import(/* webpackChunkName:"SurvivalAnalysis" */ '../pages/SurvivalAnalysis'));
const Downloads = Loadable(() => import(/* webpackChunkName:"Downloads" */ '../pages/Downloads'));
const Submission = Loadable(() => import(/* webpackChunkName:"Submission" */ '../pages/Submission'));
const Help = Loadable(() => import(/* webpackChunkName:"Help" */ '../pages/Help'));
const Contact = Loadable(() => import(/* webpackChunkName:"Contact" */ '../pages/Contact'));
const PredictionDatabase = Loadable(
  () => import(/* webpackChunkName:"PredictionDatabase" */ '../pages/PredictionDatabase')
);
const PredictionNewData = Loadable(
  () => import(/* webpackChunkName:"PredictionNewData" */ '../pages/PredictionNewData')
);
const ExploreDifferentialAnalysis = Loadable(
  () => import(/* webpackChunkName:"ExploreDifferentialAnalysis" */ '../pages/ExploreDifferentialAnalysis')
);
const ExploreMetaAnalysis = Loadable(
  () => import(/* webpackChunkName:"ExploreMetaAnalysis" */ '../pages/ExploreMetaAnalysis')
);

export interface IRoute {
  readonly path?: string;
  readonly name?: string;
  readonly icon?: ReactNode;
  readonly component?: LoadableComponent<{}>;
  readonly routes?: IRoute[];
  readonly hideInMenu?: boolean;
}

interface IRoutes {
  readonly path: string;
  readonly routes: IRoute[];
}

const routes: IRoutes = {
  path: '/',
  routes: [
    {
      path: '/home',
      name: 'Home',
      icon: <HomeOutlined />,
      component: Home,
    },
    {
      path: '/data-type',
      name: 'Data Type',
      icon: <AppstoreOutlined />,
      routes: [
        {
          path: '/data-type/mrna-lncrna',
          name: 'mRNA & LncRNA',
          component: MRNALncRNA,
        },
        {
          path: '/data-type/mirna',
          name: 'miRNA',
          component: MiRNA,
        },
        {
          path: '/data-type/methylation',
          name: 'Methylation',
          component: Methylation,
        },
        {
          path: '/data-type/cnv',
          name: 'CNV',
          component: CNV,
        },
      ],
    },
    {
      name: 'Analysis',
      icon: <ExperimentOutlined />,
      path: '/analysis',
      routes: [
        {
          path: '/analysis/differential-analysis',
          name: 'Differential Analysis',
          component: DifferentialAnalysis,
        },
        {
          path: '/analysis/survival-analysis',
          name: 'Survival Analysis',
          component: SurvivalAnalysis,
        },
        {
          path: '/analysis/tme-results',
          name: 'TME Results',
          component: TMEResults,
        },
      ],
    },
    {
      name: 'Explore',
      icon: <SearchOutlined />,
      path: '/explore',
      routes: [
        {
          path: '/explore/meta-analysis',
          name: 'Meta Analysis',
          component: ExploreMetaAnalysis,
        },
        {
          path: '/explore/differential-analysis',
          name: 'Differential Analysis',
          component: ExploreDifferentialAnalysis,
        },
      ],
    },
    // {
    //   name: 'Prediction',
    //   icon: <FileOutlined />,
    //   path: '/prediction/platform',
    //   routes: [
    //     {
    //       path: '/prediction/platform',
    //       name: 'Prediction – Platform',
    //       component: PredictionDatabase,
    //       hideInMenu: true,
    //     },
    //     {
    //       path: '/prediction/new-data',
    //       name: 'Prediction - New Data',
    //       component: PredictionNewData,
    //       hideInMenu: true,
    //     },
    //   ],
    // },
    {
      path: '/downloads',
      name: 'Downloads',
      icon: <FolderOpenOutlined />,
      component: Downloads,
    },
    {
      path: '/submission',
      name: 'Submission',
      icon: <CloudUploadOutlined />,
      component: Submission,
    },
    {
      path: '/help',
      name: 'Help',
      icon: <QuestionCircleOutlined />,
      component: Help,
    },
    {
      path: '/contact',
      name: 'Contact',
      icon: <WhatsAppOutlined />,
      component: Contact,
    },
  ],
};

export { routes };
