import { FC, ReactElement, useState, useEffect, ReactNode } from 'react';

import ProLayout, { PageContainer } from '@ant-design/pro-layout';
import { routes, IRoute } from '@/routes/index';
import { useLocation, Link, useHistory, Route, Switch, Redirect } from 'react-router-dom';

import { ThemeColorBlock, OuterWrapper, MiddleWrapper, BgBlock } from './App.style';
import BreadcrumbTitle from './components/BreadcrumbTitle';
import PageFooter from './components/PageFooter';
import { message } from 'antd';

message.config({
  duration: 0,
  maxCount: 5,
});

const getRoutesNodes = (routes: IRoute[]): ReactNode[] => {
  const routesNodes = [];
  routes.forEach(route => {
    route.routes
      ? routesNodes.push(...getRoutesNodes(route.routes))
      : routesNodes.push(
          <Route
            path={route.path}
            key={route.path}
            component={route.component}
          />
        );
  });
  return routesNodes;
};
const routesNodes = getRoutesNodes(routes.routes);

const App: FC<{}> = (): ReactElement => {
  const location = useLocation();
  const history = useHistory();

  return (
    <div
      id='test-pro-layout'
      style={{
        height: '100vh',
      }}
    >
      <ProLayout
        route={routes}
        location={{
          pathname: location.pathname,
        }}
        layout='mix'
        navTheme='light'
        onMenuHeaderClick={() => {
          history.push('/home');
        }}
        logo={null}
        // @ts-ignore
        menuItemRender={(item, dom) => <Link to={item.path!}>{dom}</Link>}
        title='CRCDB: A Comprehensive Database for Integrating and Analyzing Omics Data of Early-onset and Late-onset Colorectal Cancer'
        fixSiderbar={true}
      >
        <ThemeColorBlock />
        <BgBlock />
        <PageContainer
          header={{
            title: null,
            breadcrumb: {},
          }}
        >
          <OuterWrapper>
            <MiddleWrapper>
              <BreadcrumbTitle />
              {/*@ts-ignore*/}
              <Switch>
                <Redirect
                  exact
                  from='/'
                  to='/home'
                />
                {routesNodes}
              </Switch>
            </MiddleWrapper>
            <PageFooter />
          </OuterWrapper>
        </PageContainer>
      </ProLayout>
    </div>
  );
};

export default App;
