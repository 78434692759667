import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyle } from './style/global.style';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';

ReactDOM.render(
  <BrowserRouter>
    <ConfigProvider locale={enUS}>
      <GlobalStyle />
      <App />
    </ConfigProvider>
  </BrowserRouter>
  ,
  document.getElementById('root')
);
