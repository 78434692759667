import { HIND, JOST } from '@/constants/css';
import styled from 'styled-components';

export const BreadcrumbWrapper = styled.div`
  margin-bottom: 48px;
  color: #fff;
`;

export const Title = styled.h2`
  margin-bottom: 8px;
  font-family: ${JOST};
  font-size: 27px;
  color: #fff;
  font-weight: 500;
`;

export const SubTitle = styled.p`
  margin-bottom: 16px;
  line-height: 22px;
  font-weight: 300;
  font-family: ${HIND};
  font-size: 15px;
`;