import { FORM_ITEM_RADIUS, HIND, JOST } from '@/constants/css';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  html,body,#root{
    width: 100%;
    height: 100%;
    background-color: #F4F7FC;
    font-family: ${JOST};
  }

  .ant-pro-basicLayout-content{
    margin: 0;
    background-color: #F4F7FC;
  }

  .ant-pro-global-header{
    box-shadow: none;
  }

  .ant-pro-menu-item-title{
    font-weight: 400;
    font-family: "Hind Vadodara",-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif;
  }

  #root .ant-select-selector{
    border-radius: ${FORM_ITEM_RADIUS};
  }

  .ant-select ant-select-multiple ant-select-show-search{
    font-family: ${HIND} !important;
    font-size: 14px;
    font-weight: normal;
  }

  #root .ant-pro-sider-collapsed-button {
    border: none;
  }

  .ant-message-custom-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      font-size: 18px;
    }
  }
`;
