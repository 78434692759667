import { FC, ReactElement, useEffect, useState } from 'react';

import { BreadcrumbWrapper, Title } from './BreadcrumbTitle.style';
import { useLocation } from 'react-router-dom';

import { routes, IRoute } from '@/routes';

function getPathTitle(routes: IRoute[], pathname: string): string {
  const stack = [...routes];
  let title = '';
  while (stack.length > 0) {
    const route = stack.pop();
    if (!route.routes) {
      if (route.path === pathname) {
        title = route.name;
        break;
      }
    } else {
      stack.push(...route.routes);
    }
  }

  return title;
}

const BreadcrumbTitle: FC = (): ReactElement => {
  const location = useLocation();
  const [title, setTitle] = useState<string>('');

  useEffect(() => {
    const title = getPathTitle(routes.routes, location.pathname);
    setTitle(title);
  }, [location]);

  return (
    <BreadcrumbWrapper>
      <Title>{title}</Title>
    </BreadcrumbWrapper>
  );
};

export default BreadcrumbTitle;
