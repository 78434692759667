import { FC, ReactElement } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { CopyrightOutlined } from '@ant-design/icons';
import { THEME } from '@/constants/css';

const PageFooter: FC = (): ReactElement => {
  const history = useHistory();
  const location = useLocation();

  return (
    <PageFooterWrapper>
      <FooterLink
        onClick={() => {
          location.pathname !== '/home' && history.push('/home');
        }}
      >
        CRCDB: A Comprehensive Database for Integrating and Analyzing Omics Data of Early-onset and Late-onset
        Colorectal Cancer
      </FooterLink>

      <CopyRightWrapper>
        <CopyrightOutlined />
        <span style={{ marginLeft: 4 }}>HUST All Rights Reserved {new Date().getFullYear()}</span>
      </CopyRightWrapper>
    </PageFooterWrapper>
  );
};

export default PageFooter;

const PageFooterWrapper = styled.div`
  width: calc(100% + 48px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 48px 0 0;
  position: relative;
  padding: 12px;
  z-index: 99999;
  background-color: ${THEME};
  font-size: 14px;
  color: #fff;
  text-align: center;
  transform: translateX(-24px);

  @media (max-width: 768px) {
    width: calc(100% + 16px);
    transform: translateX(-8px);
  }
`;

const FooterLink = styled.span`
  cursor: pointer;
  line-height: 1.5;

  &:hover {
    text-decoration: underline;
  }
`;

const CopyRightWrapper = styled.div`
  margin-top: 8px;
  line-height: 1.5;
`;
