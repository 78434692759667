import styled from 'styled-components';
import { THEME } from './constants/css';
import geneBg from './assets/img/gene.jpg';

export const ThemeColorBlock = styled.div`
  height: 265px;
  width: 100%;
  background-color: ${THEME};
  position: fixed;
  top: 0;
`;

export const OuterWrapper = styled.div`
  position: absolute;
  top: 65px;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 65px);
  padding: 0 24px;

  @media (max-width: 768px) {
    top: 50px;
    padding: 0 8px;
    min-height: calc(100% - 50px);
  }
`;

export const MiddleWrapper = styled.div`
  width: 95%;
  max-width: 1400px;
  padding: 0 12px;
  margin: auto;
  flex: 1 0 auto;
  @media (max-width: 1440px) {
    width: 100%;
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    padding: 8;
  }

  @media screen and (max-width: 576px) {
    padding: 0;
  }
`;

export const BgBlock = styled.div`
  position: fixed;
  top: 265px;
  width: 100%;
  height: 100%;
  background: url(${geneBg}) no-repeat center/cover;
  backdrop-filter: blur(10px);
`;
